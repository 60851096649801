import { IObj } from '~/data/car/carColors';

export const FONT_SIZES: IObj = {
  xxs: '12px',
  xs: '14px',
  s: '16px',
  m: '18px',
  l: '24px',
  xl: '32px',
  xl_title: '40px',
  xxl: '60px',
};

export const MOBILE_FONT_SIZE_MAP: { [key: string]: string } = {
  xxs: 'xxs',
  xs: 'xxs',
  s: 'xs',
  m: 's',
  l: 'm',
  xl: 'l',
  xl_title: 'xl',
  xxl: 'xl_title',
};

export type FontSizeLabels = keyof typeof FONTS_SIZE_LABELS;

export const FONTS_SIZE_LABELS = {
  XX_SMALL: 'xxs',
  X_SMALL: 'xs',
  SMALL: 's',
  MEDIUM: 'm',
  LARGE: 'l',
  X_LARGE: 'xl',
  XL_TITLE: 'xl_title',
  XX_LARGE: 'xxl',
} as const;

type FontsSizeLabelKeys = keyof typeof FONTS_SIZE_LABELS;
export type FontSizesType = (typeof FONTS_SIZE_LABELS)[FontsSizeLabelKeys];
