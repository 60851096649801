import React from 'react';
import { ImageFile } from '~/data/types/images';

const ContactUsDeco: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      viewBox="0 0 1381 500"
    >
      <path
        d="M1370 -72.5008C1293.35 152.709 1120.28 196.943 1073.72 133.021C1057.7 111.024 1065.76 76.8966 1089.85 64.2368C1128.72 43.8093 1180.75 77.0241 1169.1 163.228C1166.4 183.178 1158.39 202.282 1146.44 218.484C1039.89 362.992 837.934 304.715 670.572 290.006C670.524 290.002 670.476 289.992 670.431 289.976C404.952 197.021 123.99 313.021 11.5 551.5"
        stroke="#00D6D1"
        strokeWidth="22"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default ContactUsDeco;
