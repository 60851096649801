import React from 'react';
import { ImageFile } from '~/data/types/images';

const YellowCircleTitle: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      width="141"
      height="52"
      viewBox="0 0 141 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.48821 46.1702C41.3302 53.2903 139.435 43.1374 137.16 23.2299C135.035 4.64471 104.306 1.47182 70.3493 3.73941C36.3929 6.00699 10.1614 10.2483 4.45515 21.8324C0.389895 30.0851 6.75015 41.9068 59.8194 34.6286"
        stroke="#FFE500"
        strokeWidth="6"
        strokeLinecap="round" />
    </Component>
  );
};

export default YellowCircleTitle;
