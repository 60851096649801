import { styled } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { COLORS } from '~/data/ui/colors';
import { ArrowsStyle } from '~/styles/useStyleGlobal';
import theme from '~/theme';

export const OurServicesSectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.gray.light,
  padding: '48px 24px 18px',
  [theme.breakpoints.up('md')]: {
    padding: '70px 28px 40px',
  },
};

export const CardsContainerStyle = styled(Swiper, {
  shouldForwardProp: (prop) => prop !== 'isMoreThenFourCards',
})<{
  isMoreThenFourCards: boolean;
}>(({ isMoreThenFourCards }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  [theme.breakpoints.up('md')]: {
    marginTop: '35px',
  },

  '& .swiper-wrapper': {
    padding: '5px 0px 30px',
  },

  [`@media (min-width: 1240px)`]: {
    width: isMoreThenFourCards ? '1200px' : '100%',
    '& .swiper-wrapper': {
      padding: '5px 0px 30px',
      margin: '0 auto',
      marginRight: isMoreThenFourCards ? '20px' : 'auto',
      width: isMoreThenFourCards ? '100%' : 'fit-content',
    },
  },
  ...ArrowsStyle,
}));

export const StyledTitleYellowLine = styled('svg')({
  position: 'absolute',
  right: '0',
  left: '25px',
  bottom: '0',
  margin: '0 auto',
  width: '125px',

  [theme.breakpoints.up('md')]: {
    width: '155px',
  },
});

export const TitleStyle = {
  position: 'relative',
  display: 'flex',
  fontSize: '32px',
  justifyContent: 'center',
  textAlign: 'center',
  width: 'fit-content',
  margin: '0 auto',
  zIndex: 1,

  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
  },
};

export const SwiperSlideStyled = styled(SwiperSlide)({
  boxShadow: '0px 15px 20px 0px rgba(0, 0, 0, 0.10)',
  backgroundColor: 'white',
  width: '201px !important',
  height: '261px !important',
  flexShrink: 0,
  borderRadius: '8px',
  border: '1px solid transparent',
  transform: 'transition all .26s ease',

  '&:hover': {
    border: `1px solid ${COLORS.primary.main}`,
  },

  '& a': {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    height: '100%',
  },

  [theme.breakpoints.up('md')]: {
    width: '273px !important',
    height: '346px !important',
  },
});
export const ServiceImageStyle = {
  width: '199px',
  height: '109px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '8px',

  [theme.breakpoints.up('md')]: {
    width: '271px',
    height: '154px',
  },
};
export const ServiceContentStyle = {
  padding: '0 16px',
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
};

export const ServiceContentTitleStyle = {
  fontFamily: 'EricaSansFOT,GillSans',
  fontWeight: '800',
  fontSize: '18px',
  lineHeight: '1.1',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
};

export const ServiceContentSubTitleStyle = {
  fontFamily: 'EricaSansFOT, GillSans',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '1.1',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    padding: '0 4px',
  },
  [theme.breakpoints.down('md')]: {
    height: '70px',
  },
};
export const ServiceLinkStyle = {
  padding: '27px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    paddingTop: '10px',
  },
};

export const ServiceLinkSpanStyle = (mobile: boolean) => ({
  position: 'relative',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  textAlign: 'center',
  color: COLORS.black.main,
  fontSize: mobile ? '16px' : '18px',

  '& svg': {
    marginTop: '-2px',
    width: mobile ? '16px' : '22px',
  },

  '&:after': {
    position: 'absolute',
    content: '""',
    backgroundColor: COLORS.yellow.main,
    width: '97%',
    height: '2px',
    left: 0,
    right: '-6px',
    margin: '0 auto',
    bottom: '2px',
  },
});

export const titleContainer = {
  position: 'relative',
};
