export const screenSizes = {
  xs: 600,
  sm: 880,
  md: 1200,
  lg: 1380,
  xl: 1536,
};

export const swiperBreakpoints = {
  900: {
    width: 900,
    slidesPerView: 3,
  },
  800: {
    width: 800,
    slidesPerView: 3,
  },
  700: {
    width: 700,
    slidesPerView: 2,
  },
  600: {
    width: 600,
    slidesPerView: 1,
    spaceBetween: 15,
  },
  300: {
    width: 258,
    slidesPerView: 1,
    spaceBetween: 15,
  },
};
export const BLOG_TEXTS = {
  allArticles: 'לכל הכתבות',
  ourBlogsRecommendations: 'הבלוג שלנו',
  of: 'מתוך',
};

export const paginationParam = { pagination: { clickable: true } };

export interface paginationParamType {
  pagination: {
    clickable: boolean;
  };
}
