import { Box, Typography } from '@mui/material';
import { FC, KeyboardEvent, useCallback, useMemo } from 'react';
import { ROUTES } from '~/data/routes';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import {
  initState,
  setSelectedSearchFlow,
} from '~/redux-toolkit/slices/searchFlowSlice';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { initSelectedFiltersState } from '~/redux-toolkit/slices/searchFiltersSlice';
import { ARIA_LABELS_RESOURCES } from '~/resources/resources';
import useStyles, { titleStyle, subtitleStyle, RootBox } from './useStyles';
import { PlanContentType } from '~/data/types/searchPlanType';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import DoubleArrow from '~/assets/icons/jsx/DoubleArrow';
import { SEARCH_LOCATION, SEARCH_PLANS } from '~/data/consts';
import { KEYBOARD_TYPES } from '~/data/constants';
import { useImageKitS3 } from '~/hooks/useImageKitS3';

interface PlanProps {
  plan: PlanContentType;
  onClickPlan?: () => void;
}

const Plan: FC<PlanProps> = ({ plan, onClickPlan }) => {
  const classes = useStyles;
  const router = useRouter();
  const searchParams = useSearchParams();
  const dispatch = useAppDispatch();
  const events = useEvents();

  const handleClickOnSearchType = useCallback(() => {
    if (onClickPlan) {
      onClickPlan();
    } else {
      events.sendEvent(
        {
          action: EActionEvent.SEARCH_FLOW_SEARCH_TYPE,
          category: ECategoryEvent.SEARCH,
        },
        { searchType: plan.title, location: SEARCH_LOCATION.homePage },
      );
      dispatch(initState());
      router.push(
        `${ROUTES.SEARCH_FLOW}/${plan.searchType}?${searchParams.toString()}`,
      );
      dispatch(setSelectedSearchFlow(plan.searchType));
      dispatch(initSelectedFiltersState());
    }
  }, [
    onClickPlan,
    events,
    plan.title,
    plan.searchType,
    dispatch,
    router,
    searchParams,
  ]);

  const ariaLabel = useMemo(
    () =>
      `${
        plan.searchType !== SEARCH_PLANS.CONTACT_US
          ? ARIA_LABELS_RESOURCES.SEARCH_FLOW_BY
          : ''
      } ${plan.title}, ${plan.subtitle}, ${plan.buttonTitle}`,
    [plan],
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYBOARD_TYPES.ENTER) {
      handleClickOnSearchType();
    }
  };
  const imageKitUrl = useImageKitS3;
  return (
    <RootBox
      onClick={handleClickOnSearchType}
      data-card="card"
      tabIndex={0}
      aria-label={ariaLabel}
      role="link"
      onKeyDown={handleKeyDown}
    >
      <Box sx={classes.innerLayout}>
        <Box sx={classes.iconDiv} data-card="iconDiv">
          <img src={imageKitUrl(plan.icon)} alt="" width="100%" />
        </Box>
        <Box sx={classes.textContainer} data-card="textContainer">
          <Typography variant="h3" sx={titleStyle}>
            {plan.title}
          </Typography>
          <Typography sx={subtitleStyle}>{plan.subtitle}</Typography>
        </Box>
        <Box sx={classes.button}>
          <Typography sx={classes.buttonLabel}>{plan.buttonTitle}</Typography>
          <DoubleArrow />
        </Box>
      </Box>
    </RootBox>
  );
};

export default Plan;
