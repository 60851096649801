import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { Navigation, Pagination } from 'swiper';
import BlogCard from '~/components/BlogCard';
import Container from '~/components/Container';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { useCurrentUrlParams } from '~/hooks/useCurrentUrlParams';
import { ROUTES } from '~/data/routes';
import { IBlog } from '~/data/types/blog/blogType';
import Button from '~/components/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import classes, {
  LinkButtonStyled,
  StyledSwiper,
  SwipeSectionStyle,
  SwipeSliderStyled,
  TitleUnderLineStyled,
  buttonGap,
  textStyle,
} from './useStyles';
import { BLOG_TEXTS, swiperBreakpoints } from './data';
import ArrowLeftIcon from '~/assets/icons/jsx/ArrowLeft';
import YellowAngleLine from '~/assets/icons/jsx/YellowAngleLine';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';

const Blogs = () => {
  const events = useEvents();
  const currentUrlParams = useCurrentUrlParams({ cleanAppParams: true });
  const blogContent = useSelector((state: RootState) => state.blog.blogs);
  const swiperRef = useRef<HTMLDivElement>(null);

  const getBlogUrl = useCallback(
    (blog: IBlog) => {
      const paramsString = currentUrlParams.toString();
      const queryParams = paramsString ? `?${paramsString}` : '';
      const labelPath = blog.labelPath.length > 0 ? blog.labelPath : 'general';
      return `${ROUTES.BLOG_PAGE}/${labelPath}/${blog.urlTitle.replaceAll(
        / /g,
        '-',
      )}${queryParams}`;
    },
    [currentUrlParams],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        const blogCard = event.currentTarget.querySelector('a');
        if (blogCard) {
          blogCard.click();
        }
      }
    },
    [],
  );

  const getAllBlogs = useCallback(() => {
    events.sendEvent({
      action: EActionEvent.HOMEPAGE_BLOG_ALL_ARTICLE,
      category: ECategoryEvent.BLOG,
    });

    return `${ROUTES.BLOGS_MAIN}`;
  }, [events]);

  useEffect(() => {
    const handleSwipeDirection = (direction: 'next' | 'prev') => {
      events.sendEvent({
        action:
          direction === 'prev'
            ? EActionEvent.HOMEPAGE_BLOG_CLICK_RIGHT
            : EActionEvent.HOMEPAGE_BLOG_CLICK_LEFT,
        category: ECategoryEvent.BLOG,
      });
    };

    const nextButton = document.querySelector(
      '#homepage-blog .swiper-button-next',
    );
    const prevButton = document.querySelector(
      '#homepage-blog .swiper-button-prev',
    );

    if (nextButton) {
      nextButton.addEventListener('click', () => handleSwipeDirection('next'));
    }

    if (prevButton) {
      prevButton.addEventListener('click', () => handleSwipeDirection('prev'));
    }

    return () => {
      if (nextButton) {
        nextButton.removeEventListener('click', () => handleSwipeDirection);
      }
      if (prevButton) {
        prevButton.removeEventListener('click', () => handleSwipeDirection);
      }
    };
  }, [events]);

  useEffect(() => {
    const swiperSlides = swiperRef.current?.querySelectorAll('.swiper-slide');

    swiperSlides?.forEach((slide) => {
      slide.addEventListener('focus', (event: Event) => {
        (event.target as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    });

    return () => {
      swiperSlides?.forEach((slide) => {
        slide.removeEventListener('focus', () => {});
      });
    };
  }, []);

  const blogsToShow = blogContent.slice(0, 10);

  return (
    <Container sx={classes.container} id="homepage-blog" ref={swiperRef}>
      <Box sx={classes.header}>
        <Typography variant="h2" sx={textStyle}>
          {BLOG_TEXTS.ourBlogsRecommendations}
          <YellowAngleLine Component={TitleUnderLineStyled} />
        </Typography>
      </Box>
      <Box sx={SwipeSectionStyle}>
        <StyledSwiper
          className="custom-swiper"
          spaceBetween={20}
          watchOverflow
          breakpoints={swiperBreakpoints}
          grabCursor
          navigation
          modules={[Pagination, Navigation]}
          role="group"
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          slideToClickedSlide
        >
          {blogsToShow.map((blog: any, index) => (
            <SwipeSliderStyled
              key={blog.id}
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <BlogCard
                blog={blog}
                url={getBlogUrl(blog)}
                customStyle={classes.blogCustomStyle}
                additionalAriaLabel={`${index + 1} ${BLOG_TEXTS.of} ${
                  blogsToShow.length
                }`}
                isHomePage={true}
                tabIndex={-1}
              />
            </SwipeSliderStyled>
          ))}
        </StyledSwiper>
        <LinkButtonStyled
          href={ROUTES.BLOGS_MAIN}
          onClick={() => getAllBlogs()}
        >
          <Button
            label={BLOG_TEXTS.allArticles}
            type={BUTTON_TYPES.MAIN}
            size={BUTTON_SIZES.LARGE}
            customStyle={buttonGap}
            roleVar="presentation"
            tabbable={false}
            icon={<ArrowLeftIcon />}
            isIconOnRight={true}
          />
        </LinkButtonStyled>
      </Box>
    </Container>
  );
};

export default Blogs;
