import { styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';
import { FONT_SIZES } from '~/data/ui/fontSizes.data';
import theme from '~/theme';

export const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '80px 24px 60px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: '103px 0 92px',
  },
};

export const contentContainer = {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: COLORS.primary.main,
  borderRadius: '8px',
  width: '100%',
  maxWidth: '631px',
  position: 'relative',
  padding: '90px 16px 20px',
  paddingTop: 'clamp(60px, 12vw ,90px)',
  gap: '24px',
  [theme.breakpoints.up('md')]: {
    padding: '90px 0 30px',
  },
};

export const textStyles = {
  maxWidth: '295px',
  textAlign: 'center',
} as const;

export const titleStyles = {
  fontSize: FONT_SIZES['l'],
};

export const descriptionStyles = {
  fontSize: FONT_SIZES['s'],
};

export const buttonTextStyles = {
  textTransform: 'none',
  marginTop: '3px',
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
} as const;

export const buttonStyles = {
  alignItems: 'center',
  gap: '8px',
  width: '132px',
  height: '35px',
  [theme.breakpoints.up('md')]: {
    height: '48px',
    width: '195px',
  },
};

export const buttonContainer = {
  display: 'flex',
  gap: '14px',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
};

export const iconStyles = {
  position: 'absolute',
  top: '0%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 'clamp(92px, 20vw ,152px)',
} as const;

export const whatsAppIconStyles = { width: '25px', height: '25px' };

export const ContactUsDecoStyled = styled('svg')(() => ({
  position: 'absolute',
  bottom: 0,
  zIndex: -1,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    width: '100%',
    height: '100%',
    minWidth: '1245px',
  },
}));
