import { IconButton, styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';

import Container from '~/components/Container';
import theme from '~/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IUseStyle } from '../ContactUsPopup/components/ScheduleMeeting/useStyles';
import Link from 'next/link';

export const ArrowsStyle = {
  '& .swiper-button-prev, .swiper-button-next': {
    display: 'flex',
    top: 'unset',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    boxSizing: 'border-box',
    gap: '10px',
    width: 35,
    height: 35,
    background: 'rgba(69, 68, 68, 0.5)',
    border: `1px solid ${COLORS.gray.light8}`,
    borderRadius: 234,
    color: 'white',
  },
  '&.custom-swiper': {
    position: 'static !important',
  },
  '& .swiper-button-prev': {
    left: '-80px',
    transform: 'translate(170%)',
  },
  '& .swiper-button-next': {
    transform: 'translateX(-170%)',
  },
  '& .swiper-button-next:after, .swiper-button-prev:after': {
    fontSize: 16,
  },
  '& .swiper-button-disabled': {
    display: 'none !important',
  },
  '& .swiper-button-next:hover, .swiper-button-prev:hover': {
    background: 'rgba(255, 254, 254, 0.5)',
    border: `1px solid ${COLORS.primary.main}`,
    color: COLORS.black.main,
  },
  '& .swiper-button-next:active, .swiper-button-prev:active': {
    background: COLORS.primary.main,
    border: `1px solid ${COLORS.primary.main}`,
    color: COLORS.black.main,
  },
  '& .swiper-pagination-bullets': {
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: '-100px',
    },
  },
};

const classes: IUseStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: COLORS.black.main,
    backgroundColor: COLORS.gray.light,
    padding: '67px 12px 67px 0',
    width: '100%',
    gap: '20px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: '74px 0 70px',
      gap: '32px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  blogCards: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '100%',
    padding: '15px 0 4px',
    overflowX: 'scroll',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '62vw',
    padding: '5px 0',
  },
  heroDescription: {
    fontSize: '20px',
    color: COLORS.black.dark,
    maxWidth: '400px',
    margin: '0',
  },
  dotsSection: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  allArticles: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  dot: {
    '&:hover': {
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
      borderRadius: '50%',
    },
  },
  customButtonBlog: {
    backgroundColor: COLORS.white.main,
    border: ` 1px solid ${COLORS.primary.main}`,
  },
  allBlogButton: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    textAlign: 'right',
    width: '90px',
    paddingBottom: '5px',
    flex: 2,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  blogCustomStyle: {
    width: '280px',
  },
  linkButtonStyle: {
    textDecoration: 'none',
  },
};

export default classes;

export const IconButtonNextPrev = styled(IconButton)`
  border: 1px solid ${COLORS.gray.light2};
  border-radius: 50%;
  padding: 4px 9px;
  margin: 5px;
`;

export const StyledSwiper = styled(Swiper)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '& .swiper-wrapper': {
    padding: '5px 0px 30px',
  },
  ...ArrowsStyle,
}));

export const SwipeSliderStyled = styled(SwiperSlide)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  width: '258px !important',
  transform: 'transition all .26s ease',

  [theme.breakpoints.up('md')]: {
    width: '282px !important',
  },
});

export const LinkButtonStyled = styled(Link)(() => ({
  marginTop: '18px',
  textDecoration: 'none',
}));

export const IconButtonNextPrevStyle = {
  border: `1px solid ${COLORS.gray.light2}`,
  padding: '4px 8px',
  margin: '3px',
};

export const SwipeSectionStyle = {
  width: '100%',
  maxWidth: '1060px',
  position: 'relative',
};

export const IconSize = { height: '14px' };

export const MainSearchContainer = styled(Container)`
  background-color: ${COLORS.gray.main};
  color: ${COLORS.black.dark};
`;

export const leftStyleIcon = {
  height: '13px',
  color: COLORS.green.dark5,
};

export const fontWeight = { color: COLORS.green.dark5 };

export const textStyle = {
  position: 'relative',
  fontFamily: 'EricaSansFOT,GillSans',
  fontWeight: '800',
  textAlign: 'center',
  fontSize: '32px',
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
  },
};

export const buttonGap = {
  marginTop: '0px',
  width: 'fit-content',
  gap: '10px',
  [theme.breakpoints.up('md')]: {
    marginTop: '12px',
  },
};

export const TitleUnderLineStyled = styled('svg')({
  position: 'absolute',
  right: '-10px',
  bottom: '-5px',
  [theme.breakpoints.down('md')]: {
    width: '89px',
    right: '-5px',
  },
});
