export class SessionStorageService {
  static setItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  static shouldDisplayMarketingPopup() {
    const shouldDisplay = sessionStorage.getItem('marketingPopupDisplayed');
    return !shouldDisplay;
  }

  static setMarketingPopupDisplayed() {
    sessionStorage.setItem('marketingPopupDisplayed', 'true');
  }
}
