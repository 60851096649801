import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  buttonStyles,
  buttonTypographyStyles,
  buttonsContainer,
  contentContainer,
  descriptionStyles,
  desktopTitleStyles,
  heroLeftDecorStyles,
  heroRightDecorStyles,
  mobileTitleStyles,
  root,
  titleStyles,
  transparentButtonStyles,
  transparentButtonTextStyles,
} from './useStyles';
import Button from '../Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import DoubleArrow from '~/assets/icons/jsx/DoubleArrow';
import ChipList from '../ChipList';
import { homePageContentType } from '~/data/types/homePageContentType';
import { QuickSearchContentType } from '~/data/QuickSearch/quickSearchType';
import RESOURCES from '~/resources';
import { CSSProperties, useMemo } from 'react';
import { ROUTES } from '~/data/routes';
import { IButtonProps } from '../Button/Button';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { useLeadLocation } from '~/hooks/useLeadLocation';
import { CAR_TARGET } from '~/data/consts';
import Link from 'next/link';

type ButtonType = {
  label: string;
  url: string;
  LOB: string;
};

type HeroProps = homePageContentType & {
  quickSearchContent?: QuickSearchContentType;
  bottomButtonProps?: IButtonProps;
  bottomButtonUrl?: string;
  customTitleStyle?: CSSProperties;
};

const Hero = ({
  title = '',
  subtitle = '',
  quickSearchContent,
  bottomButtonProps,
  bottomButtonUrl = '',
  customTitleStyle,
}: HeroProps) => {
  const events = useEvents();
  const { category } = useLeadLocation();

  //TODO: SET BACK LOBBY PAGE ROUTE
  const buttons: ButtonType[] = useMemo(
    () => [
      {
        label: RESOURCES.HOMEPAGE_NEW_CAR_LABEL,
        url: ROUTES.CAR_PAGE,
        LOB: CAR_TARGET.NEW_CAR,
      },
      {
        label: RESOURCES.USED_CAR,
        url: ROUTES.USED_CAR,
        LOB: CAR_TARGET.USED_CAR,
      },
      {
        label: RESOURCES.PRIVATE_LEASING,
        url: ROUTES.PRIVATE_LEASING,
        LOB: CAR_TARGET.LEASING,
      },
    ],
    [],
  );

  const handleOnClick = (button: ButtonType) => {
    events.sendEvent(
      {
        category,
        action: EActionEvent.HOME_PAGE_HERO_SECTION,
      },
      {
        businessLine: button.LOB,
        buttonTitle: button.label,
      },
    );
  };

  const onClickRental = () => {
    events.sendEvent({
      category: ECategoryEvent.RENTAL,
      action: EActionEvent.RENTAL_BANNER_CLICKED,
    });
  };

  const processText = (text: string) => {
    const unifiedNewlines = text.replace(/\\n|\/n/g, '\n');
    const splitText = unifiedNewlines.split('\n');
    const joinedText = unifiedNewlines.replace(/\n/g, '');
    return { mobileText: splitText, desktopText: joinedText };
  };

  const { mobileText: titleMobileLines, desktopText: titleDesktopLines } =
    useMemo(() => processText(title), [title]);
  const { mobileText: subtitleMobileLines, desktopText: subtitleDesktopLines } =
    useMemo(() => processText(subtitle), [subtitle]);

  return (
    <Box component="section" className="hero" sx={root}>
      <Box sx={heroLeftDecorStyles} />
      <Box sx={heroRightDecorStyles} />
      <Box sx={contentContainer}>
        {/* title */}
        <Typography
          variant="h1"
          sx={{ ...titleStyles, ...customTitleStyle }}
          fontFamily="fontFamily"
          fontWeight="800"
        >
          {titleMobileLines.map((line, index) => (
            <Box component="span" key={index} sx={mobileTitleStyles}>
              <span>{line}</span>
              <br />
            </Box>
          ))}
          <Box component="span" sx={desktopTitleStyles} className="hero-title">
            {titleDesktopLines}
          </Box>
        </Typography>
        {/* description */}
        <Typography sx={descriptionStyles}>
          {subtitleMobileLines.map((line, index) => (
            <Box component="span" key={index} sx={mobileTitleStyles}>
              <span>{line}</span>
              <br />
            </Box>
          ))}
          <Box component="span" sx={desktopTitleStyles}>
            {subtitleDesktopLines}
          </Box>
        </Typography>
        {quickSearchContent ? (
          <ChipList selectedSearchType={quickSearchContent} />
        ) : (
          <Box sx={buttonsContainer}>
            {buttons?.map((button, index) => {
              const { label, url } = button;
              return (
                <Link
                  href={url}
                  passHref
                  key={label}
                  id={index === 0 ? 'main-content' : ''}
                >
                  <Button
                    label={label}
                    type={BUTTON_TYPES.SECONDARY_YELLOW}
                    size={BUTTON_SIZES.SMALL}
                    customStyle={buttonStyles}
                    onClickFunc={() => handleOnClick(button)}
                    tabbable={false}
                    roleVar="presentation"
                    typographyStyle={buttonTypographyStyles}
                  />
                </Link>
              );
            })}
          </Box>
        )}
        <Link
          href={bottomButtonUrl || process.env.NEXT_PUBLIC_TEMPORARY_LS4 || ''}
          passHref
          target="blank"
        >
          <Button
            {...(bottomButtonProps
              ? bottomButtonProps
              : {
                  label: RESOURCES.HOME_PAGE_HERO_BUTTON,
                  type: BUTTON_TYPES.TRANSPARENT,
                  size: BUTTON_SIZES.SMALL,
                  customStyle: transparentButtonStyles,
                  typographyStyle: transparentButtonTextStyles,
                  icon: <DoubleArrow />,
                  isIconOnRight: true,
                  onClickFunc: onClickRental,
                })}
            tabbable={false}
            roleVar="presentation"
          />
        </Link>
      </Box>
    </Box>
  );
};

export default Hero;
