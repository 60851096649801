import React from 'react';
import { ImageFile } from '~/data/types/images';

const WhatsAppIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 23"
    >
      <path
        d="M5.09065 16.2502L5.18813 15.9596L5.00771 15.7119C4.05441 14.4027 3.49377 12.7908 3.49377 11.05C3.49377 6.66245 7.06169 3.09375 11.4479 3.09375H11.4522C15.8383 3.09375 19.4063 6.66347 19.4063 11.05C19.4063 15.4365 15.8383 19.0062 11.4522 19.0062C9.83325 19.0062 8.33404 18.5252 7.0765 17.6921L6.83907 17.5348L6.5678 17.6216L4.39791 18.3152L5.09065 16.2502Z"
        stroke="#262626"
        strokeWidth="1.1875"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0104 10.8137C12.626 12.4288 12.9925 10.5603 14.0211 11.5883C15.0128 12.5797 15.5828 12.7783 14.3263 14.0344C14.1689 14.1609 13.169 15.6826 9.6548 12.1694C6.14018 8.65572 7.66098 7.65474 7.7875 7.4974C9.047 6.23781 9.24221 6.8111 10.2339 7.80252C11.2626 8.83088 9.39482 9.19859 11.0104 10.8137Z"
        stroke="#262626"
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default WhatsAppIcon;
