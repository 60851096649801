import { useCallback, useMemo } from 'react';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import { Navigation } from 'swiper';
import { swiperBreakpoints } from './swiperBreakPoints';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { useIsMobile } from '~/hooks/useIsMobile';
import {
  OurServicesType,
  ourServiceType,
} from '~/data/types/ourServices/ourServices.types';
import {
  CardsContainerStyle,
  OurServicesSectionStyle,
  ServiceContentStyle,
  ServiceContentSubTitleStyle,
  ServiceContentTitleStyle,
  ServiceImageStyle,
  ServiceLinkSpanStyle,
  ServiceLinkStyle,
  StyledTitleYellowLine,
  SwiperSlideStyled,
  TitleStyle,
  titleContainer,
} from './useStyles';
import YellowLineTitle from '~/assets/icons/jsx/yellowLineTitleStyled';
import DoubleArrow from '~/assets/icons/jsx/DoubleArrow';
import { useImageKitS3 } from '~/hooks/useImageKitS3';

interface IOurServices {
  services: OurServicesType;
}
const OurServices = ({ services }: IOurServices) => {
  const isMobile = useIsMobile();
  const events = useEvents();

  const imageSize = useMemo(() => {
    const size = {
      width: 293,
      height: 159,
    };

    if (isMobile) {
      size.width = 200;
      size.height = 109;
    }

    return size;
  }, [isMobile]);

  const serviceClick = useCallback(
    (_event: any, service: ourServiceType) => {
      events.sendEvent(
        {
          category: ECategoryEvent.OUR_SERVICE,
          action: EActionEvent.OUR_SERVICE_CLICKED,
        },
        {
          title: service.serviceMainTitle,
        },
      );
    },
    [events],
  );

  const isMoreThenFourCards = useMemo(
    () => services.services.length > 4,
    [services.services.length],
  );
  const getImageKit = useImageKitS3;

  return (
    <>
      <Box component={'section'} sx={OurServicesSectionStyle}>
        <Box sx={titleContainer}>
          <Typography
            variant="h2"
            sx={TitleStyle}
            fontFamily="fontFamily"
            fontWeight="800"
          >
            {services.mainTitle}
            <YellowLineTitle
              Component={StyledTitleYellowLine}
              aria-hidden="true"
            />
          </Typography>
        </Box>
        <CardsContainerStyle
          navigation
          modules={[Navigation]}
          breakpoints={swiperBreakpoints(isMoreThenFourCards)}
          role="group"
          isMoreThenFourCards={isMoreThenFourCards}
        >
          {services.services.map((item, index) => (
            <SwiperSlideStyled key={index}>
              <Link
                href={item.buttonUrlLink}
                role="link"
                tabIndex={0}
                aria-label={`${services.mainTitle} ${item.serviceMainTitle} - ${item.serviceSubtitle}, ${item.buttonTitle}`}
                onClick={(e) => serviceClick(e, item)}
              >
                <Box sx={ServiceImageStyle}>
                  <img
                    width={imageSize.width}
                    height={imageSize.height}
                    // quality={100}
                    src={getImageKit(item.serviceIcon?.url || '')}
                    alt={
                      item.serviceIcon?.alternativeText || item.serviceMainTitle
                    }
                  />
                </Box>
                <Box sx={ServiceContentStyle}>
                  <Typography variant="h4" sx={ServiceContentTitleStyle}>
                    {item.serviceMainTitle}
                  </Typography>
                  <Typography sx={ServiceContentSubTitleStyle}>
                    {item.serviceSubtitle}
                  </Typography>
                </Box>
                <Box sx={ServiceLinkStyle}>
                  <Box sx={ServiceLinkSpanStyle(isMobile)} component={'span'}>
                    {item.buttonTitle}
                    <DoubleArrow />
                  </Box>
                </Box>
              </Link>
            </SwiperSlideStyled>
          ))}
        </CardsContainerStyle>
      </Box>
    </>
  );
};

export default OurServices;
