export const swiperBreakpoints = (isMoreThenFourCards: boolean) => ({
  1200: {
    width: 900,
    slidesPerView: isMoreThenFourCards ? 3 : 4,
    spaceBetween: 21,
  },
  1000: {
    width: 900,
    slidesPerView: 1,
    spaceBetween: 20,
  },
  600: {
    width: 273,
    slidesPerView: 1,
    spaceBetween: 20,
  },
  500: {
    width: 200,
    slidesPerView: 1,
    spaceBetween: 20,
  },
  300: {
    width: 200,
    slidesPerView: 1,
    spaceBetween: 15,
  },
});
