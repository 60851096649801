const YellowLineTitle: React.FC<{
  className?: string;
  style?: object;
  Component?: React.ElementType<any>;
}> = ({ className, style, Component = 'svg' }) => {
  return (
    <Component
      xmlns="http://www.w3.org/2000/svg"
      width="157"
      height="13"
      fill="none"
      viewBox="0 0 157 13"
      className={className}
      style={style}
    >
      <path
        stroke="#FFE500"
        strokeLinecap="round"
        strokeWidth="6"
        d="M154 9.996C100 0 29.5 3 3 9.99"
      ></path>
    </Component>
  );
};

export default YellowLineTitle;
