import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { SessionStorageService } from '~/services/SessionStorageService';

export const useIsDisplayMarketingPopup = () => {
  const { marketingLead } = useSelector(
    (state: RootState) => state.marketingSlice,
  );

  return useMemo(
    () =>
      marketingLead.id && SessionStorageService.shouldDisplayMarketingPopup(),
    [marketingLead],
  );
};
