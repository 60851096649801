import { Box, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import Plan from './components/Plan';
import Button from '../Button';
import { useIsMobile } from '~/hooks/useIsMobile';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import {
  PlanContentType,
  SearchPlanContentType,
} from '~/data/types/searchPlanType';
import { SEARCH_PLANS, SEARCH_PLANS_TYPES } from '~/data/consts';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import { COLORS } from '~/data/ui/colors';
import { ESourceEventOptions } from '~/data/lead/enums';
import { ROUTES } from '~/data/routes';
import RESOURCES from '~/resources';
import YellowCircleTitle from '~/assets/icons/jsx/YellowCircleTitle';
import ContactUsBubbleIcon from '~/assets/icons/jsx/ContactUsBubble';
import useStyles, { YellowCycleWrapper, mainTitle } from './useStyles';

interface SearchPlansProps {
  searchPlans: SearchPlanContentType;
}

const SearchPlans: FC<SearchPlansProps> = ({ searchPlans }) => {
  const router = useRouter();
  const events = useEvents();
  const isMobile = useIsMobile();
  const classes = useStyles;

  const handleOpenContactUs = () => {
    events.sendEvent(
      {
        action: EActionEvent.CONTACT_US_NEED_HELP_CLICKED,
        category: ECategoryEvent.CONTACT_US_PAGE,
      },
      {
        leadLocation: ESourceEventOptions.HOME_PAGE_SEARCH_PLANS,
      },
    );

    router.push({
      pathname: `${ROUTES.CONTACT_US_PAGE}`,
    });
  };
  const contactUsTitle = useMemo(() => {
    const contactUsPlan = searchPlans.SearchPlans.find(
      (s) => s.searchType === SEARCH_PLANS.CONTACT_US,
    );
    return contactUsPlan?.title || RESOURCES.HP_SEARCH_MOBILE_CONTACT_US_TITLE;
  }, [searchPlans.SearchPlans]);

  const isContactUs = useCallback(
    (planType: SEARCH_PLANS_TYPES) => planType === SEARCH_PLANS.CONTACT_US,
    [],
  );

  return (
    <>
      <Box sx={classes.root}>
        <Box sx={classes.titleDiv}>
          <Typography
            variant="h2"
            sx={mainTitle}
            fontFamily="fontFamily"
            fontWeight="800"
          >
            {searchPlans.mainTitle}
          </Typography>
          <YellowCircleTitle Component={YellowCycleWrapper} />
        </Box>
        <Box sx={classes.plansDiv}>
          {searchPlans.SearchPlans.map(
            (plan: PlanContentType) =>
              (!isMobile || !isContactUs(plan.searchType)) && (
                <Plan
                  key={`plan${plan.id}`}
                  plan={plan}
                  onClickPlan={
                    isContactUs(plan.searchType)
                      ? handleOpenContactUs
                      : undefined
                  }
                />
              ),
          )}
        </Box>
      </Box>
      {isMobile && (
        <Box sx={classes.contactUsMobileBanner}>
          <Typography fontSize={18}>{contactUsTitle}</Typography>
          <Button
            label={RESOURCES.HP_SEARCH_MOBILE_CONTACT_US_BTN}
            size={BUTTON_SIZES.FIT}
            type={BUTTON_TYPES.MAIN}
            customStyle={classes.contactUsMobileBannerButton}
            icon={<ContactUsBubbleIcon color={COLORS.black.main} />}
            onClickFunc={handleOpenContactUs}
          />
        </Box>
      )}
    </>
  );
};

export default SearchPlans;
