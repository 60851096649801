import { styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const useStyles = {
  root: {
    padding: '48px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    color: COLORS.black.main,
    alignItems: 'center',
    backgroundColor: COLORS.gray.light,
    [theme.breakpoints.up('md')]: {
      backgroundColor: COLORS.white.main,
      padding: '70px 0px 71px',
      gap: '35px',
    },
  },
  plansDiv: {
    display: 'grid',
    gridAutoRows: '1fr',
    color: COLORS.black.main,
    gap: '12px',
    padding: '0 24px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gap: '20px',
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '1270px',
      alignItems: 'center',
    },
  },
  titleDiv: {
    position: 'relative',
  },
  contactUsMobileBanner: {
    width: '100%',
    padding: '12px 24px',
    background: COLORS.white.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: '70px',
    zIndex: 10,
    boxShadow: '0px 15px 20px 0px rgba(0, 0, 0, 0.10)',
  },
  contactUsMobileBannerButton: {
    height: '35px',
    gap: '8px',
    width: '130px',
  },
};

export default useStyles;

export const mainTitle = {
  fontSize: '32px',
  zIndex: 1,
  position: 'inherit',
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
    display: 'block',
  },
};

export const YellowCycleWrapper = styled('svg')({
  display: 'block',
  position: 'absolute',
  width: '100px',
  top: '-6px',
  left: '-25px',
  [theme.breakpoints.up('md')]: {
    top: '-1px',
    left: '-27px',
    width: '121px',
  },
});
