import React from 'react';
import { ImageFile } from '~/data/types/images';

const YellowAngleLine: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      width="128"
      height="17"
      viewBox="0 0 128 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <path d="M124 9.21913C91.5919 2.6005 30.1065 0.707297 4 13"
        stroke="#FFE500"
        strokeWidth="7"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default YellowAngleLine;
