import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import RESOURCES from '~/resources';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import { CONTACT_US_TEXT } from '~/data/contactUsData';
import PhoneIconIcon from '~/assets/icons/jsx/PhoneIcon';
import WhatsAppIcon from '~/assets/icons/jsx/WhatsApp';
import ContactUsDeco from '~/assets/images/jsx/ContactUsDeco';
import ContactUsFemaleIcon from '~/assets/icons/jsx/ContactUsFemaleIcon';
import { useLeadLocation } from '~/hooks/useLeadLocation';
import { EActionEvent, useEvents } from '~/hooks/useEvents';
import { useIsMobile } from '~/hooks/useIsMobile';
import Button from '../Button';
import {
  buttonContainer,
  buttonStyles,
  buttonTextStyles,
  ContactUsDecoStyled,
  container,
  contentContainer,
  descriptionStyles,
  iconStyles,
  textStyles,
  titleStyles,
  whatsAppIconStyles,
} from './styles';
import useLineOfBusinessTarget from '~/hooks/useLineOfBusinessTarget';
import { useGetPhoneNumberBySubMedia } from '~/hooks/useGetPhoneNumberBySubmedia';
import { useCurrentCar } from '~/hooks/useCurrentCar';

enum BUTTON_ACTIONS {
  WHATSAPP = 'whatsapp',
  PHONE_NUMBER = 'phoneNumber',
}

const ContactUsBanner = () => {
  const events = useEvents();
  const isMobile = useIsMobile();
  const [isPressCall, setIsPressCall] = useState(false);
  const { category, sourceLocation } = useLeadLocation();
  const lineOfBusinessTarget = useLineOfBusinessTarget();
  const car = useCurrentCar();

  const eventData = {
    [BUTTON_ACTIONS.WHATSAPP]: {
      action: EActionEvent.CONTACT_US_MODAL_WHATSAPP,
      url: CONTACT_US_TEXT.whatsappLink,
      target: '_blank',
    },
    [BUTTON_ACTIONS.PHONE_NUMBER]: {
      action: EActionEvent.CONTACT_US_MODAL_CALL_US,
      url: `tel:${useGetPhoneNumberBySubMedia(
        process.env.NEXT_PUBLIC_PHONE as string,
      )}`,
      target: '_self',
    },
  };

  const handleOnClick = useCallback(
    (buttonAction: BUTTON_ACTIONS) => {
      const isPhoneNumberAction = buttonAction === BUTTON_ACTIONS.PHONE_NUMBER;
      const eventAction =
        isPhoneNumberAction && !isPressCall
          ? EActionEvent.EXPOSE_PHONE
          : eventData[buttonAction].action;

      events.sendEvent(
        {
          action: eventAction,
          category,
        },
        {
          leadLocation: sourceLocation,
          businessLine: lineOfBusinessTarget,
          carModel: car?.modelNameDisplayName ?? car?.dmvModelName,
          carBrand: car?.manufacturerDisplayName ?? car?.dmvManufacturerName,
        },
      );
      if (
        buttonAction === BUTTON_ACTIONS.PHONE_NUMBER &&
        !isMobile &&
        !isPressCall
      ) {
        setIsPressCall(true);
      } else {
        window.open(
          eventData[buttonAction].url,
          eventData[buttonAction].target,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events, category, isMobile, isPressCall, lineOfBusinessTarget],
  );

  const phoneNumberBySubMedia = useGetPhoneNumberBySubMedia(
    process.env.NEXT_PUBLIC_PHONE as string,
  );

  return (
    <Box component="section" sx={container}>
      <Box sx={contentContainer}>
        <ContactUsFemaleIcon style={iconStyles} />
        <Box>
          <Typography
            fontFamily="fontFamily"
            fontWeight="800"
            component={'h2'}
            sx={{ ...textStyles, ...titleStyles }}
          >
            {RESOURCES.CONTACT_US_BANNER_TITLE}
          </Typography>
          <Typography style={{ ...textStyles, ...descriptionStyles }}>
            {RESOURCES.CONTACT_US_BANNER_DESCRIPTION}
          </Typography>
        </Box>
        <Box sx={buttonContainer}>
          <Button
            label={
              isPressCall
                ? `${phoneNumberBySubMedia}`
                : RESOURCES.CONTACT_US_PHONE_CALL_US
            }
            type={BUTTON_TYPES.MAIN_YELLOW}
            size={BUTTON_SIZES.SMALL}
            typographyStyle={buttonTextStyles}
            customStyle={buttonStyles}
            onClickFunc={() => handleOnClick(BUTTON_ACTIONS.PHONE_NUMBER)}
            icon={<PhoneIconIcon />}
          />
          <Button
            label={
              isMobile
                ? RESOURCES.CONTACT_US_BY_WHATSAPP
                : RESOURCES.CONTACT_US_BANNER_WHATSAPP
            }
            type={BUTTON_TYPES.MAIN_YELLOW}
            size={BUTTON_SIZES.SMALL}
            typographyStyle={buttonTextStyles}
            customStyle={buttonStyles}
            onClickFunc={() => handleOnClick(BUTTON_ACTIONS.WHATSAPP)}
            icon={<WhatsAppIcon style={whatsAppIconStyles} />}
            roleVar="link"
          />
        </Box>
      </Box>
      <ContactUsDeco Component={ContactUsDecoStyled} />
    </Box>
  );
};

export default ContactUsBanner;
