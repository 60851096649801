import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const RootBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  background: COLORS.white.main,
  borderRadius: '8px',
  cursor: 'pointer',
  width: '100%',
  boxShadow: '0px 1px 34px 0px rgba(0, 0, 0, 0.11)',
  '&:hover': {
    background: COLORS.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
    flexDirection: 'column',
    padding: '36px 24px',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '405px',
    height: '100%',
    transition: 'all 0.18s ease-in',
    '&:hover': {
      width: '103%',
      height: '103%',
      transition: 'all 0.17s ease-out',
    },
  },
}));

const useStyles = {
  innerLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'unset',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    gap: '6px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '8px',
    },
  },
  iconDiv: {
    width: '66px',
    [theme.breakpoints.up('md')]: {
      width: '95px',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      borderBottom: `2.5px solid ${COLORS.yellow.main}`,
    },
  },
  buttonLabel: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
};

export const titleStyle = {
  fontFamily: 'EricaSansFOT,GillSans',
  fontWeight: '800',
  fontSize: '18px',
  [theme.breakpoints.up('md')]: {
    textAlign: 'center',
    fontSize: '24px',
  },
};
export const subtitleStyle = {
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    height: '80px',
    textAlign: 'center',
    fontSize: '18px',
    width: '270px',
  },
  [theme.breakpoints.down(1050)]: {
    width: '240px',
  },
  [theme.breakpoints.down(970)]: {
    width: '220px',
  },
  [theme.breakpoints.down('md')]: {
    width: 'unset',
    lineHeight: '1.1',
  },
};

export const arrowIcon = { color: COLORS.primary.dark2 };
export const buttonStyle = {
  width: '100%',
  marginTop: '20px',
  gap: '4px',
  flex: '1',
};

export default useStyles;
